import { createContext, useContext } from "react";
import { useToast, UseToastOptions } from "@chakra-ui/react";

type ToastContext = {
  setToast: (content: ToastContent) => void;
  processing: boolean;
};

type ToastContent = Partial<UseToastOptions>;

export const toastContext = createContext<ToastContext | undefined>(undefined);

export const ToastContextProvider = toastContext.Provider;

export const useToastContext = () => {
  return useContext(toastContext) as ToastContext;
};

export const useToastContextInit = (): ToastContext => {
  const toast = useToast();
  const setToast = (content: ToastContent) => {
    toast({
      title: content?.title,
      description: content?.description,
      status: content?.status,
      duration: content?.duration ?? 6000,
      isClosable: true,
    });
  };

  return {
    setToast,
    processing: false,
  };
};
